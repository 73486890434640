import { css } from 'styled-components';
import { CSSReturnType } from '../util/type-helper';

export function unstyleList(): CSSReturnType {
  return css`
    list-style: none;
    padding: 0;
    margin: 0;
  `;
}

export function unstyleButton(): CSSReturnType {
  return css`
    border: none;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
  `;
}

export function makeArrow(
  direction: 'up' | 'down',
  color = '#f2f2f2',
  size = '0.65rem',
  weight = '0.125rem'
): CSSReturnType {
  return css`
    display: inline-block;
    border: ${weight} solid ${color};
    content: '';
    height: ${size};
    transform: rotate(-45deg);
    transform-origin: center center;
    width: ${size};
    ${direction === 'down'
      ? css`
          border-right-style: none;
          border-top-style: none;
        `
      : css`
          border-left-style: none;
          border-bottom-style: none;
        `};
  `;
}

export function shadowAdjust(
  x: number,
  y: number,
  fullWidth = false
): CSSReturnType {
  // Adds responsive negative margins around an image that contains shadows
  // so that the edges of the obect are flush with the the contianer

  return css`
    display: inline-block;
    /* border: 1px solid; */

    picture {
      /* transform: none; */
      display: inline-block;
      margin: -${x}% -${y}%;
      ${fullWidth &&
      css`
        /* using 100vw to fake 100% width with max width set below */
        width: 100vw;
      `};
      max-width: ${100 + 2 * y}%;
    }
  `;
}
