import React from 'react';
import SbEditable from 'storyblok-react';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import Image, { SharedProps } from './index';
import { StoryblokAsset, BlokComponent } from '../../../types/blok.model';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';

export type ArticleImageModuleSchema = SharedProps & {
  image: StoryblokAsset;
  caption: RichTextDocument;
};

const ArticleImageBlock: BlokComponent<ArticleImageModuleSchema> = ({
  blok,
  Dynamic,
}) => {
  const image = useStoryblokImage(blok.image.filename);

  return (
    <SbEditable content={blok}>
      <Image
        {...blok}
        alt={blok?.alt || 'Preview image'}
        image={image}
        caption={<StoryblokRichText content={blok.caption} Dynamic={Dynamic} />}
      />
    </SbEditable>
  );
};

export default ArticleImageBlock;
