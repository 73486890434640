import React from 'react';
import SbEditable from 'storyblok-react';
import TextFeatureServices, { TextFeatureServicesProps } from '.';
import { BlokComponent, StoryblokAsset } from '../../../types/blok.model';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';
import { hasRichTextContent } from '../../components/RichText/RichText.utils';

export interface TextFeatureServicesBlokProps
  extends Omit<TextFeatureServicesProps, 'icon'> {
  icon?: StoryblokAsset;
  description: RichTextDocument;
  listPrimary: RichTextDocument;
  listSecondary: RichTextDocument;
}

const TextFeatureServicesBlok: BlokComponent<TextFeatureServicesBlokProps> = ({
  blok,
  Dynamic,
}) => {
  const icon = useStoryblokImage(blok.icon?.filename ?? '');

  const description = hasRichTextContent(blok.description) ? (
    <StoryblokRichText content={blok.description} Dynamic={Dynamic} />
  ) : null;

  const listPrimary = hasRichTextContent(blok.listPrimary) ? (
    <StoryblokRichText content={blok.listPrimary} Dynamic={Dynamic} />
  ) : null;

  const listSecondary = hasRichTextContent(blok.listSecondary) ? (
    <StoryblokRichText content={blok.listSecondary} Dynamic={Dynamic} />
  ) : null;

  return (
    <SbEditable content={blok}>
      <TextFeatureServices
        {...blok}
        icon={icon}
        description={description}
        listPrimary={listPrimary}
        listSecondary={listSecondary}
      />
    </SbEditable>
  );
};

export default TextFeatureServicesBlok;
