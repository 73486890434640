import styled, { ThemeProvider } from 'styled-components';
import React, { FC } from 'react';
import { breakpointUp } from '../../../styles/mediaUtils';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';

const ContentWrapper = styled.div<{
  width?: number;
  fullWidth?: boolean;
}>`
  max-width: ${({ width, theme }) => width || theme.maxContainerWidthLg};
  position: relative;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.siteOuterMargin};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};

  @media ${breakpointUp.md} {
    padding: 0 ${({ theme }) => theme.siteOuterMarginLg};
  }
`;

const ColourBlock = styled.section`
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.fg};
  padding: ${({ theme }) => `${theme.sectionPaddingSm} 0`};
  overflow: hidden;

  & + & {
    padding-top: 0;
  }

  @media ${breakpointUp.md} {
    padding: ${({ theme }) => `${theme.sectionPaddingLg} 0`};
  }
`;

type Props = {
  className?: string;
  theme?: 'dark' | 'light';
};

export const ThemeContentWrapper: FC<Props> = ({
  className,
  theme = 'light',
  children,
}) => {
  return (
    <ThemeProvider theme={theme === 'light' ? defaultTheme : darkTheme}>
      <ColourBlock className={className}>
        <ContentWrapper>{children}</ContentWrapper>
      </ColourBlock>
    </ThemeProvider>
  );
};

export default ContentWrapper;
