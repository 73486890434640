import React from 'react';
import SbEditable from 'storyblok-react';
import { StoryblokAsset, BlokComponent } from '../../../types/blok.model';
import Image, { SharedProps } from '.';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';

export type ImageBlockSchema = SharedProps & {
  image: StoryblokAsset;
};

const ImageBlock: BlokComponent<ImageBlockSchema> = ({ blok }) => {
  const image = useStoryblokImage(blok.image.filename);

  return (
    <>
      <SbEditable content={blok}>
        <Image {...blok} alt={blok?.alt || 'Preview image'} image={image} />
      </SbEditable>
    </>
  );
};

export default ImageBlock;
