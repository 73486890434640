import { RichTextDocument } from './StoryblokRichText';
import { ContentBit } from './BlockRenderer';

export const hasRichTextContent = (content: RichTextDocument): boolean => {
  if (!content || !content.content || content.content.length === 0) {
    return false;
  }

  return content.content.some((bit: ContentBit) => {
    switch (bit.type) {
      case 'paragraph':
      case 'heading':
        return bit.content && bit.content.length > 0;
      case 'blok':
      case 'bullet_list':
      case 'ordered_list':
      case 'blockquote':
      case 'code_block':
      case 'horizontal_rule':
        return true;
      default:
        return false;
    }
  });
};
